import Alpine from "alpinejs";
import ToastComponent from "../../vendor/usernotnull/tall-toasts/dist/js/tall-toasts";
// import { Dropzone } from "dropzone";
// const dropzone = new Dropzone("#uploadDropZone", { url: "/admin/upload-files2" });
// window.dropzone = dropzone;
require("./bootstrap");
require("./custom");

import Tribute from "tributejs";
import Croppr from "croppr";
import select2 from "select2";

window.Tribute = Tribute;
window.select2 = select2;
window.Croppr = Croppr;
Alpine.data("ToastComponent", ToastComponent);

window.Alpine = Alpine;
Alpine.start();
