function initializeSelect2(type, tags = false) {
    let select = $('.' + type);
    select.select2({
        ajax: {
            async: true,
            url: '/api/v1/' + type,
            delay: 250,
            dataType: 'json',
            data: function (params) {
                return {
                    search: params.term,
                    status: 1
                };
            },
            cache: true,
            processResults: function (data, params) {
                console.log('processResults');
                let result = $.map(data.data, function (obj) {
                    if (type === 'users') {
                        obj.text = obj.name + ' ' + obj.last_name + ' (' + obj.email + ')'
                    } else {
                        obj.text = obj.text || obj.name;
                    }
                    return obj;
                });
                return {
                    results: result,
                };
            },
        },
        tags: tags,
        closeOnSelect: false
    });

    select.on('select2:close', function (e) {
        Livewire.emit(type + 'Data', select.val());
    });
}

function price(mediaPrice, customPrice) {
    let price = $('.price');
    if (customPrice === 1) {
        $('.other-value-price').slideDown();
        price.val('other_value');
    }

    price.change(function () {
        let priceValue = $(this).find(":selected").val();
        if (priceValue === 'other_value') {
            $('.other-value-price').slideDown();
        } else {
            $('.other-value-price').slideUp();
        }
    });

    let enable_exclusive_price = $('#enable_exclusive_price');
    if (enable_exclusive_price.is(':checked'))
        $('.divExclusivePrice').slideDown();
    enable_exclusive_price.change(function () {
        if ($(this).is(':checked')) {
            $('.divExclusivePrice').slideDown();
        } else {
            $('.divExclusivePrice').slideUp();
        }
    });
}


window.onload = function () {
    // $("#peopleForInvite").hide()
    initializeSelect2('users', false);
    window.livewire.on('refreshCredits', () => {
        $(document).ready(function () {
            initializeSelect2('users', false);
        });

    });

    /////////////////////////////////////////////////////////


    window.livewire.on('loadCropper', () => {
        console.log("Loaded cropper")
        let croppr = new Croppr('.image', {
            onInitialize: function (instance) {
            },
            onCropStart: function (value) {
                //
            },
            onCropEnd: function (value) {
                document.getElementById('crop_height').value = value.height
                document.getElementById('crop_width').value = value.width
                document.getElementById('crop_x').value = value.x
                document.getElementById('crop_y').value = value.y

                // When JS dynamically changes element input, you have to dispatch input event again, it tells to livewire to refresh the value
                let widthElement = document.getElementById('crop_width');
                widthElement.dispatchEvent(new Event('input'));

                let heightElement = document.getElementById('crop_height');
                heightElement.dispatchEvent(new Event('input'));

                let widthResizeElement = document.getElementById('resize_width');
                widthResizeElement.dispatchEvent(new Event('input'));

                let heightResizeElement = document.getElementById('resize_height');
                heightResizeElement.dispatchEvent(new Event('input'));

                let xElement = document.getElementById('crop_x');
                xElement.dispatchEvent(new Event('input'));

                let yElement = document.getElementById('crop_y');
                yElement.dispatchEvent(new Event('input'));
            }
        });
    });


    window.livewire.on('loadEditPictureJs', () => {

        console.log('loadEditPictureJs');

        $(document).on('change', '#users', function () {
            var data = $('#users').select2("val");
            Livewire.emit('userData', data);
        });


        document.getElementById('brightness').addEventListener('change', (e) => {
            document.getElementById('brightness_label').innerText = 'Brightness ' + e.target.value
        })
        document.getElementById('brightness_label').innerText = 'Brightness ' + document.getElementById('brightness').value

        document.getElementById('blur').addEventListener('change', (e) => {
            document.getElementById('blur_label').innerText = 'Blur ' + e.target.value
        })
        document.getElementById('blur_label').innerText = 'Blur ' + document.getElementById('blur').value

        document.getElementById('opacity').addEventListener('change', (e) => {
            document.getElementById('opacity_label').innerText = 'Opacity ' + e.target.value
        })
        document.getElementById('opacity_label').innerText = 'Opacity ' + document.getElementById('opacity').value

        document.getElementById('contrast').addEventListener('change', (e) => {
            document.getElementById('contrast_label').innerText = 'Contrast ' + e.target.value
        })
        document.getElementById('contrast_label').innerText = 'Contrast ' + document.getElementById('contrast').value

        document.getElementById('sharpen').addEventListener('change', (e) => {
            document.getElementById('sharpen_label').innerText = 'Sharpen ' + e.target.value
        })
        document.getElementById('sharpen_label').innerText = 'Sharpen ' + document.getElementById('sharpen').value

        document.getElementById('greyscale').addEventListener('change', (e) => {
            document.getElementById('greyscale_label').innerText = 'Greyscale ' + e.target.value
        })
        document.getElementById('greyscale_label').innerText = 'Greyscale ' + document.getElementById('greyscale').value

        document.getElementById('aspectRatio').addEventListener('change', function () {
            if (document.getElementById('aspectRatio').checked) {
                console.log('checked')
                document.getElementById('resize_width').addEventListener('keyup', updateAspectRatioHeight)
                document.getElementById('resize_height').addEventListener('keyup', updateAspectRatioWidth)
            } else {
                console.log('not checked')
                document.getElementById('resize_width').removeEventListener('keyup', updateAspectRatioHeight)
                document.getElementById('resize_height').removeEventListener('keyup', updateAspectRatioWidth)
            }
        })

        function updateAspectRatioHeight() {
            let w = Number(document.getElementById('width').value)
            let h = Number(document.getElementById('height').value)
            let diff = w / h
            let rw = Number(document.getElementById('resize_width').value)
            let rh = Number(document.getElementById('resize_height').value)
            let newH = rw / diff
            document.getElementById('resize_height').value = Math.round(newH)
            let heightResizeElement = document.getElementById('resize_height');
            heightResizeElement.dispatchEvent(new Event('input'));
        }

        function updateAspectRatioWidth() {
            let w = Number(document.getElementById('width').value)
            let h = Number(document.getElementById('height').value)
            let diff = w / h
            let rw = Number(document.getElementById('resize_width').value)
            let rh = Number(document.getElementById('resize_height').value)
            let newW = rh * diff
            document.getElementById('resize_width').value = Math.round(newW)
            let widthResizeElement = document.getElementById('resize_width');
            widthResizeElement.dispatchEvent(new Event('input'));
        }
    });

    window.livewire.on('refreshForm', (mediaPrice = null, customPrice = null) => {

        price(mediaPrice, customPrice);
    });

    window.livewire.on('price', (mediaPrice = null, customPrice = null) => {
        price(mediaPrice, customPrice);
    });

    window.livewire.on('access', function (){
        let access = $("#access").val()
        if (access === 'invited_only') {
            $("#peopleForInvite").slideDown()
        } else {
            $("#peopleForInvite").slideUp()
        }
    })

    window.livewire.on('accessUpload', function (){
        let access = $("#uploadManipulationAccess").val()
        if (access === 'invited_only') {
            $("#peopleForInviteUpload").slideDown()
        } else {
            $("#peopleForInviteUpload").slideUp()
        }

        let accessMultiple = $("#imagesManipulationAccess").val()
        if (accessMultiple === 'invited_only') {
            $("#peopleForInviteMultipleEdit").slideDown()
        } else {
            $("#peopleForInviteMultipleEdit").slideUp()
        }
    })

    window.livewire.on('usersMultipleEdit', function (){
        let access = $("#imagesManipulationAccess").val()
        if (access === 'invited_only') {
            $("#peopleForInviteMultipleEdit").slideDown()
        } else {
            $("#peopleForInviteMultipleEdit").slideUp()
        }
    })

    window.livewire.on('refreshSize', (width, height) => {
        $('#width').val(width);
        $('#height').val(height);
    });

    window.livewire.on('loadFocusPoint', (focus_x, focus_y) => {
        let imageOriginal = document.getElementById("imageOriginal");
        let l = (focus_x * (imageOriginal.width / 100)) - 26;
        let t = (focus_y * (imageOriginal.height / 100)) - 10;
        let newImage = document.getElementById("focus-point");
        newImage.setAttribute('class', 'overlays');
        newImage.style.left = l + "px";
        newImage.style.top = t + "px";

        let focusXInput = document.getElementById('focus-x');
        let focusYInput = document.getElementById('focus-y');

        let scrolledY = $(window).scrollTop();
        $(window).scroll(function () {
            scrolledY = $(window).scrollTop();
        });


        imageOriginal.addEventListener('click', function () {
            let bounds = this.getBoundingClientRect();
            let left = bounds.left;
            let top = bounds.top;
            let x = event.pageX - left;
            let y = event.pageY - top;

            let focusxPerc = ((event.pageX - left) / imageOriginal.width) * 100;
            let focusyPerc = ((event.pageY - top - scrolledY) / imageOriginal.height) * 100;

            focusXInput.value = focusxPerc;
            focusYInput.value = focusyPerc;

            focusXInput.dispatchEvent(new Event('input'));
            focusYInput.dispatchEvent(new Event('input'));


            newImage.style.left = x - 26 + "px";
            newImage.style.top = y - 10 - scrolledY + "px";

            // Racunica je: (kordinata(px) / sirina slike) * 100
        });
    });

    window.livewire.on('fileUpload', (event) => {

        let files = event.dataTransfer.files;
        let fileObject = files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
            window.livewire.emit('fileUploads', reader.result)
        }
        reader.readAsDataURL(fileObject);
    })
}

$(document).on('click', '#copyLink', function (e) {
    e.preventDefault()
    let uploadLinkText = document.querySelector('.uploadLinkText').textContent;
    navigator.clipboard.writeText(uploadLinkText).then(function() {
        alert('Copied!');
    }, function(err) {
        console.error('Could not copy text: ', err);
    });
})
